<template>
  <div class="diseaseList">
    <!-- 筛选查询框 @change="selectGoodsByIdBtn"-->
    <el-form :inline="true">
      <el-button type="primary" size="small" v-waves @click="handleAdd"
        >新增</el-button
      >
      <el-form-item label="所属门店:" style="margin:0 20px" size="small">
        <el-select
          v-model="listQuery.store_id"
          placeholder="请选择"
          clearable
          multiple
          collapse-tags
        >
          <div v-for="(item, index) in filterStoreList" :key="index">
            <el-option
              :label="item.store_name"
              :value="item.store_id"
            ></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="分类: " style="margin-right:20px" size="small">
        <el-select
          v-model="listQuery.productCategory"
          placeholder="请选择"
          clearable
        >
          <div v-for="item in categoryArr" :key="item.id">
            <el-option :label="item.categoryName" :value="item.id"></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称: " style="margin-right:20px" size="small">
        <el-input
          v-model="productName"
          placeholder="请输入商品名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="getListData" size="small" v-waves
        >搜索</el-button
      >
    </el-form>
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="row.store_id + ''" />
        </template>
        <template slot="img" slot-scope="{ row }">
          <img :src="row.img" alt style="width:100%" />
        </template>

        <template slot="status" slot-scope="{ row }">
          <span>{{
            row.status === 0
              ? "上架"
              : row.status === 1
              ? "预售"
              : row.status === 2
              ? "补货"
              : row.status === 3
              ? "下架"
              : "-"
          }}</span>
        </template>

        <!-- 是否开启sku -->
        <template slot="isSku" slot-scope="{ row }">
          <el-switch
            :value="row.isSku == 1"
            active-color="#afb86d"
            inactive-color="#ff4949"
            @change="recommendChange(row, $event)"
          >
          </el-switch>
        </template>

        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button size="mini" type="text" @click="handleEdit(row)" primary
              >详情(编辑)</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="ejectupdatImgs(row)"
              primary
              >商品图</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleSku(row)"
              primary
              v-if="row.isSku"
              >商品规格</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>

      <!-- 产品提交表单 -->
      <el-dialog
        :title="inpitTypeName"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <div style="height: 400px;overflow-y: auto;">
          <el-form :model="dialogForm" :label-width="formLabelWidth">
            <el-form-item label="商品分类: " required>
              <el-select
                v-model="dialogForm.productCategory"
                placeholder="请选择商品分类"
              >
                <div v-for="item in categoryArr" :key="item.id">
                  <el-option
                    :label="item.categoryName"
                    :value="item.id"
                  ></el-option>
                </div>
              </el-select>
            </el-form-item>
            <el-form-item label="商品状态:">
              <el-select
                v-model="dialogForm.status"
                placeholder="不填写默认为上架"
              >
                <div v-for="item in statusArr" :key="item.id">
                  <el-option :label="item.status" :value="item.id"></el-option>
                </div>
              </el-select>
            </el-form-item>

            <el-form-item label="所属门店: " required>
              <el-select
                v-model="dialogForm.store_id"
                placeholder="请选择"
                style="width: 100%;"
                clearable
              >
                <div v-for="(item, index) in filterStoreList" :key="index">
                  <el-option
                    :label="item.store_name"
                    :value="item.store_id"
                  ></el-option>
                </div>
              </el-select>
            </el-form-item>

            <el-form-item label="商品名称" required>
              <el-input v-model="dialogForm.productName"></el-input>
            </el-form-item>
            <!-- <el-form-item label="商品库存">
              <el-input v-model="dialogForm.stockNum"></el-input>
            </el-form-item> -->
            <el-form-item label="商品描述(请勿输入&特殊字符)" required>
              <el-input
                v-model="dialogForm.introduce"
                type="textarea"
              ></el-input>
            </el-form-item>

            <el-form-item label="售价" required>
              <el-input v-model="dialogForm.price"></el-input>
            </el-form-item>

            <el-form-item label="划去的标价">
              <el-input v-model="dialogForm.discountPrice"></el-input>
            </el-form-item>
            <el-form-item label="商品编码">
              <el-input v-model="dialogForm.productCode"></el-input>
            </el-form-item>
            <!-- <el-form-item label="商品视频链接">
              <el-input v-model="dialogForm.videoLink"></el-input>
            </el-form-item> -->

            <el-form-item label="商品封面图" width="100" required>
              <div class="demo-image__preview">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="dialogForm.coverImg"
                  :preview-src-list="[dialogForm.coverImg]"
                >
                </el-image>
              </div>
            </el-form-item>
            <el-form-item label="" width="100">
              <!-- 上传文件到服务器 -->
              <el-upload
                :action="action"
                :multiple="false"
                :on-remove="handleRemove"
                :on-success="coverImgBtn"
                :file-list="fileList"
                list-type="picture"
                name="formData"
                :show-file-list="false"
                :before-upload="beforeUpload"
              >
                <el-button
                  class="addBtn"
                  size="small"
                  type="primary"
                  :loading="uploadLoadng"
                  >上传商品封面</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  请上传jpg/png文件，500px X 500px
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogFormVisible = false"
            >取 消</el-button
          >
          <el-button class="addBtn" type="primary" @click="save"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!-- sku弹窗 -->
    <el-dialog
      title="商品规格"
      :visible.sync="skuVisible"
      :close-on-click-modal="false"
    >
      <!-- <div style="height: 450px;overflow-y: auto;"> -->
      <div class="sku_box">
        <h4>新增商品规格</h4>
        <el-form :model="skuForm" label-width="75px">
          <el-form-item label="规格名称">
            <el-input v-model="skuForm.sku" clearable></el-input>
          </el-form-item>
          <el-form-item label="价钱">
            <el-input
              v-model="skuForm.price"
              clearable
              type="number"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button type="success" size="mini" @click="addSkuBtn"
            >确定新增</el-button
          >
        </div>
      </div>

      <div class="sku_box">
        <h4>商品规格展示</h4>
        <div class="skuList">
          <div
            v-for="item in skuData"
            :key="item.id"
            :class="{ skuBg: item.id === skuActive }"
            @click="skuActiveBtn(item.id)"
          >
            {{ item.sku }} (价格:{{ item.price }}元)
          </div>
        </div>
        <div class="dialog-footer">
          <el-button type="danger" size="mini" @click="deleteSkuById"
            >删除商品规格</el-button
          >
        </div>
      </div>
      <!-- </div> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="skuVisible = false">返 回</el-button>
      </div>
    </el-dialog>

    <!-- 图片弹窗 -->
    <el-dialog
      title=""
      :visible.sync="dialogImgVisible"
      :close-on-click-modal="false"
    >
      <div class="imgUrlBox">
        <h2>商品主图</h2>
        <div class="imgUrlBoxShow">
          <div
            v-for="(item, index) in headerImg"
            :key="index"
            @click="handleEditImg(item)"
          >
            <img :src="item.imgUrl" alt style="width:100%" />
          </div>
        </div>
        <el-upload
          class="upload-demo"
          :action="action"
          :multiple="false"
          :on-remove="handleRemove"
          :on-success="headerImgBtn"
          :file-list="fileList"
          list-type="picture"
          name="formData"
          :show-file-list="false"
        >
          <el-button class="addBtn" size="small" type="primary"
            >添加商品主图(5张)</el-button
          >
          <div slot="tip" class="el-upload__tip">
            请上传jpg/png文件，500px X 500px, 上传后在尾部新增一条
          </div>
        </el-upload>
      </div>

      <div class="detailsImgBox">
        <h2>商品详情图</h2>
        <div class="detailsImgBoxShow">
          <div
            v-for="(item, index) in detailsImg"
            :key="index"
            @click="handleEditImg(item)"
          >
            <img :src="item.imgUrl" alt style="width:100%" />
          </div>
        </div>
        <el-upload
          class="upload-demo"
          :action="action"
          :multiple="false"
          :on-remove="handleRemove"
          :on-success="detailsImgBtn"
          :file-list="fileList"
          list-type="picture"
          name="formData"
          :show-file-list="false"
        >
          <el-button class="addBtn" size="small" type="primary"
            >添加商品详情图（10张）</el-button
          >
          <div slot="tip" class="el-upload__tip">
            请上传jpg/png文件，500px X 500px,上传后在尾部新增一条
          </div>
        </el-upload>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogImgVisible = false" plain>返 回</el-button>
          <!-- <el-button type="primary" @click="updataImgBtn">确 定</el-button> -->
        </div>
      </div>
    </el-dialog>

    <!-- 图片操作弹窗 -->
    <el-dialog
      title="操作选中的图片"
      :visible.sync="imgEditVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="imgObj">
        <el-form-item label="" :label-width="formLabelWidth" width="100">
          <img
            :src="imgObj.imgUrl"
            alt
            style="width:200px; border: 1px dotted rgb(93, 93, 93);"
          />
        </el-form-item>

        <el-form-item label="" :label-width="formLabelWidth" width="100">
          <el-upload
            class="upload-demo"
            :action="action"
            :multiple="false"
            :on-remove="handleRemove"
            :on-success="updateImgBtn"
            :file-list="fileList"
            list-type="picture"
            name="formData"
          >
            <el-button size="small" type="primary">修改替换图片</el-button>
            <div slot="tip" class="el-upload__tip">
              请上传jpg/png文件，500px X 500px
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="排序大小" :label-width="formLabelWidth">
          <el-input v-model="imgObj.sort" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="imgEditVisible = false"
          >取 消</el-button
        >
        <el-button class="cancelBtn" @click="handleDeleteImgItem"
          >删 除</el-button
        >
        <el-button class="addBtn" type="primary" @click="handleSaveImgItem"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectGoods,
  addGoods,
  updateGoods,
  updateSkuState,
  deleteGoodbyid,
  selectCategory,
  addSku,
  selectSku,
  deleteSku,
  selectImg,
  addImg,
  updateImg,
  deleteImg,
} from "@/services/mall/goods.js";
import { IP } from "@/services/config.js";
import ElTable from "@/components/elTable/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import listHeader from "./listHeader";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    StoreToString,
  },
  created() {
    this.getListData();
    // 查询一级分类
    selectCategory({
      pageNumber: 1,
      pageSize: 100,
      type: 2,
      store_id: this.userInfo.store_id,
    }).then((res) => {
      this.categoryArr = res.data;
    });
  },

  data() {
    return {
      dialogForm: {
        coverImg: "",
      },
      dialogImgVisible: false, //产品图维护
      imgEditVisible: false,
      readVisible: false,
      imgObj: {},
      loading: false,
      listQuery: {
        type: 2,
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      status: 0,
      statusArr: [
        { id: 0, status: "上架" },
        { id: 1, status: "预售" },
        { id: 2, status: "补货" },
        { id: 3, status: "下架" },
      ],
      goodsTitle: "",
      tableData: [],
      categoryId: "",
      productName: "",
      // 一级分类 二级分类 商品
      select_one_id: "",
      categoryArr: [],
      nextCategoryArr: [],
      dialogFormVisible: false,
      formLabelWidth: "120px",
      // action: "http://127.0.0.1:6688/uploadFile/add",
      action: IP + "/uploadFile/add",
      fileList: [],
      form: {},
      inpitTypeName: "",
      headerImg: [],
      detailsImg: [],
      /* sku相关参数 */
      skuData: [], //sku数组
      skuActive: "",
      skuForm: {
        //新增sku
        productId: "",
        price: "",
        sku: "",
      },
      skuVisible: false,
      uploadLoadng: false,
    };
  },
  computed: {
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.store_id =
        listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectGoods({
        ...listQuery,
        // id: this.userInfo.id,
        productName: this.productName,
      });
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 通过筛选查商品 */
    selectGoodsByIdBtn() {
      this.getListData();
    },
    /* 分页 */
    handleCurrentChange(e) {
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 显示商品sku设置 */
    recommendChange(row, val) {
      this.$confirm(
        `是否确认${val == "1" ? "打开" : "隐藏"}商品规格设置？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        if (val === true) {
          row.isSku = "1";
        } else {
          row.isSku = "0";
        }
        updateSkuState({ id: row.id, isSku: row.isSku }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "操作成功",
              offset: 100,
            });
            this.getListData();
          } else {
            this.$message({
              type: "error",
              message: "操作失败",
              offset: 100,
            });
            if (val == "0") {
              row.isSku = 1;
            } else {
              row.isSku = 0;
            }
          }
        });
      });
    },
    /* sku弹窗 */
    handleSku(row) {
      this.skuVisible = true;
      this.skuActive = "";
      this.skuForm.productId = row.id;
      this.selectSkuById(row.id); //查询sku
    },
    /**
     * 查询sku
     * @param {productId} 商品id
     */
    selectSkuById(productId) {
      selectSku({ productId }).then((res) => {
        this.skuData = res.data;
      });
    },

    /* 新增sku */
    addSkuBtn() {
      if (
        this.skuForm.price === "" ||
        this.skuForm.sku === "" ||
        this.skuForm.minNum === "" ||
        this.skuForm.maxNum === ""
      ) {
        return this.$message.error("选项不能为空");
      }

      addSku(this.skuForm).then(() => {
        this.selectSkuById(this.skuForm.productId);
        this.$message.success("新增成功");
      });
    },
    deleteSkuById() {
      if (this.skuActive === "") {
        return this.$message.error("请选择要删除的sku");
      }
      console.log("this.skuActive", this.skuActive);
      deleteSku(this.skuActive).then(() => {
        this.selectSkuById(this.skuForm.productId);
      });
    },
    skuActiveBtn(id) {
      console.log(id);
      this.skuActive = id;
    },

    /* 文件上传 */
    beforeUpload(e) {
      console.log("开始上传", e);
      this.uploadLoadng = true;
    },
    coverImgBtn(e) {
      this.dialogForm.coverImg = e.data;
      this.uploadLoadng = false;
      // console.log("上传成功", this.dialogForm.coverImg);
    },

    handleRemove(e, fileList) {
      console.log("移除---------------", e, fileList);
    },

    handleAdd() {
      this.dialogFormVisible = true;
      this.inpitTypeName = "新增商品";
      this.headerImg = [];
      this.detailsImg = [];
      this.fileList = [];
      this.dialogForm = {};
      this.select_one_id = "";
    },

    handleEdit(row) {
      console.log(row);
      this.dialogFormVisible = true;
      this.inpitTypeName = "修改商品详情";
      this.dialogForm = JSON.parse(JSON.stringify(row));
      // if (this.dialogForm.store_id) {
      //   this.dialogForm.store_id = this.dialogForm.store_id?.split(",");
      // }
      this.headerImg = [];
      this.detailsImg = [];
      this.fileList = [];
    },
    handlePictureCardPreview(file) {
      this.readVisible = true;
    },
    /* 商品图片管理 */
    ejectupdatImgs(row) {
      console.log(row);
      this.dialogImgVisible = true;
      // this.inpitTypeName = "商品图片维护";
      this.form = JSON.parse(JSON.stringify(row));
      this.fileList = [];
      //查询
      this.handleSeletImg();
      this.handleSeletDeatilImg();
    },

    /* 根据商品id查询主图 */
    handleSeletImg() {
      selectImg({ productId: this.form.id, imgType: 1 }).then((res) => {
        console.log("主图", res);
        this.headerImg = res.data;
      });
    },

    /* 根据商品id查询详情图 */
    handleSeletDeatilImg() {
      selectImg({ productId: this.form.id, imgType: 2 }).then((res) => {
        console.log("详情图", res);
        this.detailsImg = res.data;
      });
    },

    /* 新增商品主图 */
    headerImgBtn(e) {
      addImg({
        productId: this.form.id,
        imgUrl: e.data,
        imgType: 1,
      }).then((res) => {
        this.fileList = [];
        this.handleSeletImg();
      });
    },

    /* 新增详情图 */
    detailsImgBtn(e) {
      addImg({
        productId: this.form.id,
        imgUrl: e.data,
        imgType: 2,
      }).then((res) => {
        this.fileList = [];
        this.handleSeletDeatilImg();
      });
    },

    /* 编辑图片弹窗 */
    handleEditImg(row) {
      console.log(row);
      this.imgEditVisible = true;
      this.imgObj = JSON.parse(JSON.stringify(row));
    },

    /* 编辑图片 */
    updateImgBtn(e) {
      this.fileList = [];
      this.imgObj.imgUrl = e.data;
    },

    /* 保存编辑的图片 */
    handleSaveImgItem() {
      console.log(this.imgObj);
      updateImg(this.imgObj).then((resp) => {
        console.log(resp);
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.imgEditVisible = false;
        //查询
        this.handleSeletImg();
        this.handleSeletDeatilImg();
      });
    },

    /* 删除图片 */
    handleDeleteImgItem() {
      console.log(this.imgObj.id);
      this.$confirm("此操作将永久删除该图片吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteImg({ id: this.imgObj.id }).then((resp) => {
            console.log(resp);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.imgEditVisible = false;
            //查询
            this.handleSeletImg();
            this.handleSeletDeatilImg();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /* 保存 */
    save() {
      //必填字段校验
      if (
        !this.dialogForm.productCategory ||
        !this.dialogForm.price ||
        !this.dialogForm.store_id ||
        !this.dialogForm.productName ||
        !this.dialogForm.introduce ||
        !this.dialogForm.coverImg
      ) {
        this.$message.error("请填写必填字段");
        return;
      }
      if (this.dialogForm.store_id.length > 0) {
        this.dialogForm.store_id = this.dialogForm?.store_id?.join(",");
      }
      if (this.inpitTypeName == "新增商品") {
        addGoods({ ...this.dialogForm, type: 2 }).then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$message.success("操作成功");
            this.dialogFormVisible = false;
            this.getListData();
          } else {
            this.$message.error("操作失败");
          }
        });
        return;
      }
      if (this.inpitTypeName == "修改商品详情") {
        console.log("修改商品详情------------");
        updateGoods(this.dialogForm).then((resp) => {
          if (resp.code == 200) {
            this.$message.success("修改成功");
            this.dialogFormVisible = false;
            this.getListData();
          } else {
            this.$message.error("修改失败");
          }
        });
      }
    },
    handleDelete(row) {
      this.$confirm(
        `此操作将永久删除'${row.productName}'商品吗, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        deleteGoodbyid({ id: row.id }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.diseaseList {
  width: 100%;
}
.el-pagination {
  text-align: center;
  margin: 20px;
}
.goodsTitle:hover {
  cursor: pointer;
}
.upload-demo {
  margin: 20px 0;
}

/* sku */
.sku_box {
  border: 1px solid rgb(182, 182, 182);
  padding: 20px 10px;
  border-radius: 20px;
  margin: 15px 0 0;
}
.sku_box > h4 {
  margin: 0 0 10px;
}
.skuList {
  border: 2px dotted rgb(189, 189, 189);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
}
.skuList > div {
  padding: 5px 10px;
  height: 30px;
  background: rgb(245, 138, 44);
  border-radius: 10px;
  color: white;
  margin: 3px 10px;
}
.skuList > div:hover {
  cursor: pointer;
}
.skuBg {
  background: rgb(254, 66, 3) !important;
}

/* 详情页 */
.imgUrlBox,
.detailsImgBox {
  width: 100%;
}

.imgUrlBox {
  margin-bottom: 50px;
}
.imgUrlBoxShow {
  display: flex;
}
.imgUrlBoxShow > div {
  margin: 0px 10px 0 0;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px dotted rgb(93, 93, 93);
}
.imgUrlBoxShow > div:hover {
  cursor: pointer;
}
.detailsImgBoxShow > div {
  border: 1px dotted rgb(93, 93, 93);
  margin: 0 auto;
}
.detailsImgBoxShow > div:hover {
  cursor: pointer;
}
.detailsImgBoxShow > div img {
  width: 100%;
}

/* 按钮 */
.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
